<template>
    <div class="entity-search">
        <PageTitle icon="fa fa-clone" main="Documento(s)" sub="Rejeitado(s)" />
          <b-card no-body bg-variant="light"  text-variant="dark">
                <b-tabs card  align="center">
                       <b-tab active>
                             <template v-slot:title>
                                     <i class="fa fa-building"></i> <strong>Empresa - {{users_files.length}} </strong>
                                 </template>
                                   <b-button  title="Solicitar Autorização"  size="lq" variant="outline-dark" class="mb-2 ml-2  fa fa-check-square-o" 
                                   v-if="users_files.length > 0"
                                   :disabled="allow_document_unique"
                                    @click="showMsgBoxAllow"  > Solicitar Autorização
                                     <b-spinner small v-show="allow_document_unique"></b-spinner>
                                    </b-button>
                                    <b-table
                                        class="table-responsive"
                                        hover
                                        head-variant="ligth"
                                        :items="users_files"
                                        :fields="fieldsUserFile"
                                    >
                                        <template v-slot:cell(actions)="data">
                                            <b-button
                                                title="Visualizar"
                                                size="sm"
                                                variant="light"
                                                v-show="!showPreview"
                                                @click="
                                                    ;[
                                                        loadUserFile(data.item),
                                                        openModalUserFilePDF()
                                                    ]
                                                "
                                                class="mr-2 mt-2"
                                            >
                                                <i class="fa fa-file-pdf-o"></i>
                                                Visualizar
                                            </b-button>
                                            <b-button
                                                title="Editar registro"
                                                size="sm"
                                                v-if="!data.item.return_document_change_id > 0"
                                                variant="light"
                                                @click="
                                                    ;[
                                                        openModalResposibleFile(data.item),
                                                    ]
                                                "
                                                class="mr-2 mt-2"
                                            >
                                                <i class="fa fa-pencil"></i>
                                                Editar
                                            </b-button>
                                            <b-button
                                                    title="Confirmar Envio"
                                                    size="sm"
                                                    v-if="data.item.storage_file_id > 0 && data.item.changed && !data.item.return_document_change_id > 0"
                                                    variant="light"
                                                    :disabled="check_document_unique"
                                                    @click="checkUserFileFtp(data.item)"
                                                    class="mr-2 mt-2"
                                                >
                                                    <i class="fa fa-check"></i>
                                                    Confirmar Envio
                                                    <b-spinner small v-show="check_document_unique"></b-spinner>
                                            </b-button>
                                        </template>
                                    </b-table>
                                    
                       </b-tab>
                          <b-tab >
                             <template v-slot:title>
                                     <i class="fa fa-building"></i> <strong>Empresa (Complemento) - {{users_files_complements.length}} </strong>
                                 </template>
                                   <b-button  title="Solicitar Autorização"  size="lq" variant="outline-dark" class="mb-2 ml-2  fa fa-check-square-o" 
                                   v-if="users_files_complements.length > 0"
                                   :disabled="allow_document_unique"
                                    @click="showMsgBoxAllowCompl"  > Solicitar Autorização
                                     <b-spinner small v-show="allow_document_unique"></b-spinner>
                                    </b-button>
                                    <b-table
                                        class="table-responsive"
                                        hover
                                        head-variant="ligth"
                                        :items="users_files_complements"
                                        :fields="fieldsUserFile"
                                    >
                                        <template v-slot:cell(actions)="data">
                                            <b-button
                                                title="Visualizar"
                                                size="sm"
                                                variant="light"
                                                v-show="!showPreview"
                                                @click="
                                                    ;[
                                                        loadUserFile(data.item),
                                                        openModalUserFilePDF()
                                                    ]
                                                "
                                                class="mr-2 mt-2"
                                            >
                                                <i class="fa fa-file-pdf-o"></i>
                                                Visualizar
                                            </b-button>
                                            <b-button
                                                title="Editar registro"
                                                size="sm"
                                                v-if="!data.item.return_document_change_id > 0"
                                                variant="light"
                                                @click="
                                                    ;[
                                                        openModalResposibleFile(data.item),
                                                    ]
                                                "
                                                class="mr-2 mt-2"
                                            >
                                                <i class="fa fa-pencil"></i>
                                                Editar
                                            </b-button>
                                            <b-button
                                                    title="Confirmar Envio"
                                                    size="sm"
                                                    v-if="data.item.storage_file_id > 0 && data.item.changed && !data.item.return_document_change_id > 0"
                                                    variant="light"
                                                    :disabled="check_document_unique"
                                                    @click="checkUserFileFtp(data.item)"
                                                    class="mr-2 mt-2"
                                                >
                                                    <i class="fa fa-check"></i>
                                                    Confirmar Envio
                                                    <b-spinner small v-show="check_document_unique"></b-spinner>
                                            </b-button>
                                        </template>
                                    </b-table>
                                    
                       </b-tab>
                        <b-tab >
                             <template v-slot:title>
                                     <i class="fa fa-newspaper-o"></i> <strong>Funcionário - {{company_employee_files.length}}  </strong> 
                                 </template>
                                 <ul>
                                    <li v-for="company in companys" :key="company.id"  >
                                        <ListCompany :company= "company"/>
                                    </li>
                                </ul>
                        </b-tab>
                         <b-tab >
                             <template v-slot:title>
                                     <i class="fa fa-calendar"></i> <strong>Mensal - {{company_monthly_files.length}}  </strong>
                                 </template>
                                  <ul>
                                    <li v-for="company in companymonthlys" :key="company.id"  >
                                        <ListCompanyMonthly :company= "company"/>
                                    </li>
                                </ul>
                        </b-tab>
                <!--b-tab>
                         <template v-slot:title>
                                     <i class="fa fa-calendar"></i> <strong>Teste  </strong>
                        </template>
                        <b-embed
                            type="iframe"
                            aspect="16by9"
                            src="https://app.powerbi.com/reportEmbed?reportId=9c8d6f0a-407f-441d-8023-967b570aecd9&autoAuth=true&ctid=9170c7a9-c010-4a67-8684-63cafa5d9ee9"
                            allowfullscreen
                        ></b-embed>
                </b-tab!-->
                </b-tabs>
          </b-card>
                <b-modal
            id="modalResposibleFilePDF"
            ref="modal"
            title-tag="h6"
            centered
            size="lg"
            ok-only
            title="Visualização do Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
        >
            <div>
                <pdf :src="users_file.url_file"></pdf>
            </div>
        </b-modal>
         <b-modal
             v-model="modalFileShow"
            id="modalResposibleFile"
            title-tag="h6"
            centered
            size="lg"
            title="Registro Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
    
        >
            <b-row>
                   <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>

            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShow=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
    </div>
</template>

<script>
import ListCompany from './../util/ListCompany' 
import ListCompanyMonthly from './../util/ListCompanyMonthly' 
import { baseApiUrl,showError } from '@/global'
import axios from 'axios'
import PageTitle from '../template/PageTitle'
import pdf from 'vue-pdf'

export default {
    name: 'RejectFileEmployee',
    components: { PageTitle, ListCompany,ListCompanyMonthly,pdf},
    data: function() {
        return {
            companys:[],
            companymonthlys:[],
            users_files: [],
            users_file: {},
            users_files_complements: [],
            company_monthly_files: [],
            file: null,
            file_upload_show: false,
             allow_document_unique: false,
             check_document_unique: false,
            company_employee_files:[],
            headerBgVariant: 'dark',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'warning',
            footerTextVariant: 'dark',
            fieldsUserFile: [
                {
                    key: 'name_type_document_unique',
                    label: 'Documento',
                     variant: 'light',
                    sortable: true
                },
                {
                    key: 'justify_comments',
                    label: 'Justificativa',
                     variant: 'light',
                    sortable: false
                },
                { key: 'actions', label: 'Ações', variant: 'light' }
            ]
        }
    },
    computed: {
        compan() {
            return this.$store.state.compan
        },
        user() {
            return this.$store.state.user
        }
    },
    methods: {
        showMsgBoxAllow() {
            this.boxCancel = ''
            this.$bvModal
                .msgBoxConfirm('Deseja solicitar aprovação ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                              this.allow_document_unique = true
                        await this.allowFiles()
                    }
                })
        },
          showMsgBoxAllowCompl() {
            this.boxCancel = ''
            this.$bvModal
                .msgBoxConfirm('Deseja solicitar aprovação ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                              this.allow_document_unique = true
                        await this.allowFilesCompl()
                    }
                })
        },
        loadCompanys() {
            const url = `${baseApiUrl}/companysrejectuser/${this.user.id}`
            axios(url).then(res => {
                this.companys = res.data
            })
        },
        loadCompanyMonthly() {
            const url = `${baseApiUrl}/companysmonthlyrejectuser/${this.user.id}`
            axios(url).then(res => {
                this.companymonthlys = res.data
            })
        },
        async  loadUsesFileRejects() {
            const url = `${baseApiUrl}/usersfilerejectuser/${this.user.id}`
         await  axios(url).then(res => {
                this.users_files = res.data
            })
        },
        async  loadUsesFileComplRejects() {
            const url = `${baseApiUrl}/usersfilerejectusercompl/${this.user.id}`
         await  axios(url).then(res => {
                this.users_files_complements = res.data
            })
        },
         async loadMonthlyFiles() {

            const url = `${baseApiUrl}/companydocumentmonthlyfilereject/${this.user.id}`
            await axios.get(url).then(res => {
                this.company_monthly_files = res.data
            })

        },  
        loadUserFile(item) {
            this.users_file = { ...item }
        },
         async    loadCompanyEmployeeFiles() {
            const url = `${baseApiUrl}/companyemployeefilesuserscompanys/${this.user.id}`
             await    axios(url).then(res => {
                this.company_employee_files = res.data
            })
        },
        openModalUserFilePDF() {
            this.$bvModal.show('modalResposibleFilePDF')
        },
        openModalResposibleFile(item) {
            this.users_file = { ...item }
            this.file_upload_show = false
            this.$bvModal.show('modalResposibleFile')
        },
         closeModalEmployeeFile() {
            this.$bvModal.hide('modalResposibleFile')
        },
         async saveImagem() {

            this.file_upload_show = true
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('user_id_unique', this.user.id)
            formData.append('type_document_unique_id', this.users_file.type_document_unique_id)

            await axios
                .post(`${baseApiUrl}/storagefiles`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( async () => {
                    this.$toasted.global.defaultSuccess()
                     await  this.getLastStorageFile()
                    this.type_doc_show =true
                })
                .finally(async ()=>{
                    if(this.users_file.storage_file_id > 0){
                      await  this.saveFile()
                    }else{
                          this.$toasted.global.defaultWarning({
                                msg: "Nâo foi possivel gravar o arquivo!Tente novamente....",
                                });
                    }
                })
                .catch(showError)
        },
        async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesuniquebylast/?name_file=${this.file.name}&user_id_unique=${this.user.id}&type_document_unique_id=${this.users_file.type_document_unique_id}`
            await axios(url).then(
                res =>
                    (this.users_file = {
                        storage_file_id: res.data.id,
                        name_file: res.data.name_file,
                        path_file: res.data.path_file,
                        changed: 1,
                        id: this.users_file.id,
                        type_document_unique_id: this.users_file
                            .type_document_unique_id
                    })
            )
        },
        async saveFile() {
            this.users_file.user_id_file = this.user.id
            const method = this.users_file.id ? 'put' : 'post'
            const id = this.users_file.id
                ? `/${this.users_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/usersfiles${id}`,
                this.users_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.closeModalEmployeeFile()
                    this.loadUsesFileRejects()
                })
                .catch(showError)
        },
        async checkUserFileFtp(item) {

            this.check_document_unique = true;

            this.users_file = { ...item }
            
            if(item.storage_file_id > 0){
                  await axios['put'](
                `${baseApiUrl}/usersfilesftp/${this.users_file.id}`,
                this.users_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                     this.loadUsesFileRejects()
                })
                 .finally( () => {
                     this.check_document_unique = false
                })
                .catch(showError)
            }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }
          
        },
         async  allowFiles(){
                      await axios['post'](
                `${baseApiUrl}/usersfileschange/${this.user.id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()        
                    this.loadUsesFileRejects()        
                })
                .finally( () => {
                     this.allow_document_unique = false
        
                })
                .catch(showError)

        },
         async  allowFilesCompl(){
                      await axios['post'](
                `${baseApiUrl}/usersfileschangecompl/${this.user.id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()        
                    this.loadUsesFileComplRejects()        
                })
                .finally( () => {
                     this.allow_document_unique = false
        
                })
                .catch(showError)

        },
        async refreshListRejected(){
            await this.loadCompanyFiles()
            await this.loadUserFiles()
        },
         async loadCompanyFiles() {
            const url = `${baseApiUrl}/companyemployeefilefluig/${this.user.id}`
            await axios.get(url).then(async() => {
                 await   this.loadCompanyEmployeeFiles()
            })
        },
        async loadUserFiles() {
              if(this.user.id > 0){
                     const url = `${baseApiUrl}/usersfilefluig/${this.user.id}`
                     await  axios(url).then(res => {
                        this.users_files = res.data
                    })
              }
        },

    },
   async mounted() {
        await this.loadCompanyEmployeeFiles()
        this.loadCompanys()
        await  this.loadUsesFileRejects()
        await  this.loadUsesFileComplRejects()
        await this.loadMonthlyFiles()
        await this.loadCompanyMonthly()
    }
}
</script>

<style>
.entity-search {
    font-size: 0.75rem;
}

.entity-search button {
    font-size: 0.75rem;
}

.entity-search select {
    font-size: 0.75rem;
}

.entity-search input {
    font-size: 0.75rem;
}

#modalGroupEntity {
    font-size: 0.75rem;
}

#modalGroupEntity button {
    font-size: 0.75rem;
}

#modalGroupEntity select {
    font-size: 0.75rem;
}

@keyframes slide-in {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(40px);
    }
}

.slide-enter-active {
    animation: slide-in 1s ease;
    transition: opacity 1s;
}

.slide-leave-active {
    animation: slide-out 1s ease;
    transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
</style>
