<template>
        <div class="rejectfileemployee">
        <PageTitle
            icon="fa fa-clone"
            main="Documento Rejeitado"
            sub="Funcionário"
        />
            <b-card bg-variant="light" text-variant="dark" title="Contrato(s)">
                <b-card-text>
                    Selecione o contrato para apresentar o(s) funcionário(s).
                    </b-card-text>
                    <b-row>
                         <b-col md="3" sm="6">
                            <b-table
                                class="table-responsive"
                                :items="companys"
                                :fields="fieldsCompany"
                                 :select-mode="selectMode"
                                  @row-selected="onRowSelected"
                                  selectable
                                   ref="selectableTable"
                                >
                                 <template #cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                    <span class="sr-only">Selecionado</span>
                                    </template>
                                    <template v-else>
                                    <span aria-hidden="true">&nbsp;</span>
                                    <span class="sr-only">Não Selecionado</span>
                                    </template>
                                </template>

                            </b-table>
                        
                    </b-col>
                     <b-col md="3" sm="6">
                            <b-table
                                class="table-responsive"
                                :select-mode="selectMode"
                                @row-selected="onRowEmployeeSelected"
                                  selectable
                                :items="company_employees"
                                :fields="fieldsEmployee"
                                >
                               <template #cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                    <span class="sr-only">Selecionado</span>
                                    </template>
                                    <template v-else>
                                    <span aria-hidden="true">&nbsp;</span>
                                    <span class="sr-only">Não Selecionado</span>
                                    </template>
                                </template>
                                <template v-slot:cell(actions)="data">
                                     <b-button
                                            title="Solicitar Autorização"
                                            size="sm"
                                            variant="info"
                                            @click="changeFile(data.item)"
                                            :disabled="request_accept"
                                            class=" mr-2"
                                        >

                                         <div  class="d-flex justify-content-center ">
                                            <b-spinner v-show="request_accept" label="Enviando..."></b-spinner>
                                        </div>
                                        <div v-show="!request_accept">
                                                <i  v-show="!request_accept" class="fa fa-exchange"></i>
                                                Solicitar Autorização
                                         </div>
                                    </b-button>
                                </template>
                            </b-table>
                         </b-col>
                          <b-col md="6" sm="12">
                             <b-table
                                        class="table-responsive"
                                        hover
                                        head-variant="ligth"
                                        :items="company_employee_files"
                                        :fields="fieldsFile"
                                    >
                                    <template v-slot:cell(actions)="data">
                                            <b-button
                                                title="Visualizar"
                                                size="sm"
                                                variant="outline-dark"
                                                @click="
                                                    ;[
                                                        loadFile(data.item),
                                                        openModalPDF()
                                                    ]
                                                "
                                                class="mr-2 mt-2"
                                            >
                                                <i class="fa fa-file-pdf-o"></i>
                                                Visualizar
                                            </b-button>
                                             <b-button
                                                title="Editar Arquivo"
                                                size="sm"
                                                variant="outline-dark"
                                                @click="[loadFile(data.item),openModalFile()]"
                                                class="mr-2 mt-2"
                                            >
                                                <i class="fa fa-pencil"></i>
                                                Editar
                                            </b-button>
                                          
                                    </template>
 
                             </b-table>
                          </b-col>
                    </b-row>
                </b-card>
                 <b-modal
                    id="modalPDF"
                    ref="modal"
                    title-tag="h6"
                    centered
                    size="lg"
                    ok-only
                    title="Visualização do Documento"
                >
                    <div>
                        <pdf :src="company_employee_file.url_file"></pdf>
                    </div>
                </b-modal>
                <b-modal
                    v-model="modalFileShow"
                    id="modalFile"
                    title-tag="h6"
                    centered
                    size="lg"
                    title="Arquivo"
            
                >
            <b-row  v-show="company_employee_file.has_date">
                  <b-col md="3" sm="12">
                    <b-form-group
                        label="Data de Validade:"
                        label-for="company_employee_file-validade_date"
                        >
                        <b-form-input
                            id="company_employee_file-validade_date"
                            type="date"
                            v-model="company_employee_file.validade_date"
                            size="sm"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-show="company_employee_file.has_date && company_employee_file.validade_date">
                <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
              <b-row v-show="!company_employee_file.has_date">
                <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
              </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShow=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
        </div>
</template>
<script>

import { baseApiUrl,showError } from '@/global'
import axios from 'axios'
import PageTitle from '../template/PageTitle'
import pdf from 'vue-pdf'

export default {
    name: 'RejectFileCompanyEmployee',
    components: { PageTitle,pdf},
    data: function(){
        return{
                selectMode: 'single',
                selected: [],
                selectedEmployee: [],
                companys: [],
                company_employee_files: [],
                company_employees:[],
                company_employee_file:{},
                company:{},
                storage_file: {},
                request_accept:false,
                file_upload_table: false,
                file_doc_show: true,
                file_upload_show: false,
                modalFileShow: false,
                company_employee: {},
                file: null,
                fieldsCompany: [
                {
                    key:'selected',
                    label:'...'
                },
                {
                    key: 'name_build_company',
                    label: 'Contrato',
                        variant: 'light',
                    sortable: true
                },
                                {
                    key: 'contact_number_company',
                    label: 'Nº Contrato',
                        variant: 'light',
                    sortable: true
                },

                ],
                fieldsEmployee: [
                {
                    key:'selected',
                    label:'...'
                },
                {
                    key: 'name_employee',
                    label: 'Nome Funcionário',
                    sortable: false
                },
                { key: 'actions', label: '', variant: 'light' },
                ],
                fieldsFile: [
                {
                    key: 'name_type_document',
                    label: 'Documento',
                     variant: 'light',
                    sortable: true
                },
                {
                    key: 'justify_comments',
                    label: 'Justificativa',
                     variant: 'light',
                    sortable: false
                },
                {
                    key: 'changed',
                    label: 'Alterado',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                { key: 'actions', label: '', variant: 'light' },
                { key: 'actions1', label: '', variant: 'light' },
                { key: 'actions2', label: '', variant: 'light' },
                ]
        }
    },
    computed: {
        compan() {
            return this.$store.state.compan
        },
        user() {
            return this.$store.state.user
        }
    },
     methods: {
            showMsgBoxSendFile(item) {
                this.boxCancel = ''
                this.$bvModal
                .msgBoxConfirm('Deseja enviar o arquivo ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                        this.file_upload_table = true    
                        await this.checkFileFtp(item)
        
                    }
                })
            },
            onRowSelected(items) {
                    this.selected = items
                    let row = this.selected[0]
                     row ? this.loadCompanyEmployees(row) : this.company_employees = []    
            },
            onRowEmployeeSelected(items) {
                    this.company_employee ={}
                    this.selectedEmployee = items
                    let row = this.selectedEmployee[0]
                    this.company_employee = row
                     row ? this.loadCompanyEmployeeFiles(row) : this.company_employee_files = []    
            },
            loadCompanyEmployee() {
                const url = `${baseApiUrl}/companysrejectuser/${this.user.id}`
                axios(url).then(res => {
                 this.companys = res.data
                })
            },  
            loadCompanyEmployees(item) {
             const url = `${baseApiUrl}/companyemployeesreject/${item.id}`
                 axios(url).then(res => {
                    this.company_employees = res.data
                })
            },
            async loadCompanyEmployeeFiles(item) {
                const url = `${baseApiUrl}/companyemployeefilesusers/${item.id}`
              await  axios(url).then(res => {
                        this.company_employee_files = res.data
                    })
            },
            openModalPDF() {
                this.$bvModal.show('modalPDF')
            },
            openModalFile() {
                this.file_doc_show = false

                this.$bvModal.show('modalFile')
            },
            closeModalFile(){
                this.$bvModal.hide('modalFile')
            },
            loadFile(item) {
                this.company_employee_file = { ...item }
            },
            async saveImagem() {

            this.file_upload_show = true
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('company_id_storage', this.company_employee_file.company_id_employee)
            formData.append('company_employee_id_storage', this.company_employee_file.employee_id)
            formData.append('type_document_id_storage', this.company_employee_file.type_document_id_file)

            await axios
                .post(`${baseApiUrl}/storagefiles`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( async () => {
                    this.$toasted.global.defaultSuccess()
                     await  this.getLastStorageFile()
                   
                })
                .finally(async ()=>{
                    if(this.company_employee_file.storage_file_id > 0){
                      await  this.saveFile()
                    }else{
                          this.$toasted.global.defaultWarning({
                                msg: "Nâo foi possivel gravar o arquivo!Tente novamente....",
                                });
                    }
                })
                .catch(showError)
        },
         async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesbylast/?name_file=${this.file.name}&company_id_storage=${this.company_employee.company_id_employee}&company_employee_id_storage=${this.company_employee.id}&type_document_id_storage=${this.company_employee_file.type_document_id_file}`
            await axios(url).then(
               res =>
                    (this.company_employee_file = {
                        ...this.company_employee_file,
                        storage_file_id: res.data.id,
                        name_file: res.data.name_file,
                        path_file: res.data.path_file,
                        status_type_document_file: 2,
                        return_folder_code: this.company_employee.return_folder_code,
                        id: this.company_employee_file.id,
                        company_employee_id: this.company_employee_file
                            .company_employee_id,
                        type_document_id_file: this.company_employee_file
                            .type_document_id_file
                    })
                  
            ) 
        },
         async saveFile() {
            
            const method = 'put'
            const id = this.company_employee_file.id
                ? `/${this.company_employee_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companyemployeefiles${id}`,
                this.company_employee_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                   
                })
                .finally(async () => {
                      await this.checkFileFtp(this.company_employee_file)
                    this.closeModalFile()
                    //await this.loadCompanyEmployeeFiles(this.company_employee)
                    this.file_upload_show = false
                })
                .catch(showError)
        },
        async checkFileFtp(item) {

            this.company_employee_file = {...item}
            if(this.company_employee_file.storage_file_id > 0){
                  await axios['put'](
                `${baseApiUrl}/companyemployeefilesftp/${this.company_employee_file.id}`,
                this.company_employee_file
            )
                .then(async() => {
                    this.$toasted.global.defaultSuccess()
                     await  this.loadCompanyEmployeeFiles(this.company_employee)
                       this.file_upload_table = false   
                       this.file_upload_show = false
                })
                .finally( () => {
                     this.file_upload_table = false   
                       this.file_upload_show = false
                   
                })
                .catch(showError)
            }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }

                  
        },
        async changeFile(item) {           

             this.request_accept = true

                await axios['post'](
                `${baseApiUrl}/companyemployeefileschange/${item.id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()   
                    this.request_accept = false   
                    this.loadCompanyEmployee()
                      
                })

                .catch(showError)

                  this.request_accept = false   
        },
        /*
        loadCompanyDocumentEmployeeFiles() {
            const url = `${baseApiUrl}/documentemployeefilesreject/${this.company_employee_file.company_doc_employee_id}`
           axios(url).then(res => {
                this.company_employee_files = res.data
            })
        },
        */
     },
    async mounted() {
        await this.loadCompanyEmployee()
    }
}
</script>

<style>
.rejectfileemployee {
    font-size: 0.75rem;
}

.rejectfileemployee button {
    font-size: 0.75rem;
}

#modalFile {
     font-size: 0.75rem;
}

#modalFile button{
     font-size: 0.75rem;
}

</style>