<template>
    <div class="type_document-admin">
        <b-form
            v-show="mode === 'save' || mode === 'remove' || mode === 'read'"
        >
            <input
                id="type_document-id"
                type="hidden"
                v-model="type_document.id"
            />
            <b-row>
                <b-col md="5" sm="12">
                    <b-form-group
                        label="Descrição:"
                        label-for="type_document-name_type_document"
                    >
                        <b-form-input
                            id="type_document-name_type_document"
                            type="text"
                            v-model="type_document.name_type_document"
                            ref="searchFocus"
                            size="sm"
                            :readonly="
                                mode === 'remove' ||
                                    mode === 'read' ||
                                    mode === 'insert'
                            "
                            placeholder="Descrição..."
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4" sm="12">
                    <b-form-group
                        label="Index Base:"
                        label-for="type_document-initial_type_document"
                    >
                        <b-form-input
                            id="type_document-initial_type_document"
                            type="text"
                            v-model="type_document.initial_type_document"
                            required
                            size="sm"
                            :readonly="
                                mode === 'remove' ||
                                    mode === 'read' ||
                                    mode === 'insert'
                            "
                            placeholder="Reduzido..."
                        />
                    </b-form-group>
                </b-col>

                <b-col md="3" sm="12">
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                v-model="type_document.blocked"
                                true-value="1"
                                false-value="0"
                                type="checkbox"
                                id="type_document-blocked"
                                :disabled="
                                    mode === 'remove' ||
                                        mode === 'read' ||
                                        mode === 'insert'
                                "
                            />
                            <label
                                class="form-check-label"
                                for="type_document-blocked"
                            >
                                Bloqueado?
                            </label>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3" sm="12">
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                v-model="type_document.require_employee"
                                true-value="1"
                                false-value="0"
                                type="checkbox"
                                id="type_document-require_employee"
                                :disabled="
                                    mode === 'remove' ||
                                        mode === 'read' ||
                                        mode === 'insert'
                                "
                            />
                            <label
                                class="form-check-label"
                                for="type_document-blocked"
                            >
                                Obrigatório para Funcionário?
                            </label>
                        </div>
                    </div>
                </b-col>
                 <b-col md="3" sm="12">
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                v-model="type_document.require_responsible"
                                true-value="1"
                                false-value="0"
                                type="checkbox"
                                id="type_document-require_responsible"
                                :disabled="
                                    mode === 'remove' ||
                                        mode === 'read' ||
                                        mode === 'insert'
                                "
                            />
                            <label
                                class="form-check-label"
                                for="type_document-blocked"
                            >
                                Obrigatório para Responsável?
                            </label>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                 <b-col md="12" sm="12">
                    <b-form-textarea
                    id="type_document-description_type_document"
                    v-model="type_document.description_type_document"
                    placeholder="Detalhamento.."
                    rows="3"
                    max-rows="6"
                    :readonly="
                                mode === 'remove' ||
                                    mode === 'read' ||
                                    mode === 'insert'
                            "
                    ></b-form-textarea>
                 </b-col>
            </b-row>
            <br>
            <b-row>
                <b-col md="6" sm="12">
                    <b-form-group
                        label="Url de Acesso:"
                        label-for="type_document-url_type_document"
                    >
                        <b-form-input
                            id="type_document-url_type_document"
                            type="text"
                            v-model="type_document.url_type_document"
                            size="sm"
                            :readonly="
                                mode === 'remove' ||
                                    mode === 'read' ||
                                    mode === 'insert'
                            "
                            placeholder="Url de Acesso..."
                        />
                    </b-form-group>
                </b-col>
                <b-col md="8" sm="6">
                    <b-form-group
                        label="Arquivo Modelo:"
                        label-for="type_document-model_attach_id_type_document"
                    >
                    <b-form-file
                        accept=".docx"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        size="sm"
                    ></b-form-file>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                   <b-col md="3" sm="12">
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                v-model="type_document.has_date"
                                true-value="1"
                                false-value="0"
                                type="checkbox"
                                id="type_document-has_date"
                                :disabled="
                                    mode === 'remove' ||
                                        mode === 'read' ||
                                        mode === 'insert'
                                "
                            />
                            <label
                                class="form-check-label"
                                for="type_document-has_date"
                            >
                                Exige Data de Validade?
                            </label>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-form>
        <b-row>
            <b-col xs="12">
                <b-button
                    title="Adicionar"
                    size="sm"
                    class="mt-2 mb-2 fa fa-plus"
                    variant="dark"
                    v-show="mode !== 'save' && mode !== 'remove'"
                    @click="alterModo"
                ></b-button>
                <b-button
                    title="Salvar"
                    size="sm"
                    variant="dark"
                    class="mt-2 mb-2 ml-2 fa fa-save"
                    v-if="mode === 'save'"
                    @click="save"
                ></b-button>
                <b-button
                    title="Excluir"
                    size="sm"
                    variant="dark"
                    class="mt-2 mb-2 ml-2 fa fa-trash"
                    v-if="mode === 'remove'"
                    @click="remove"
                ></b-button>
                <b-button
                    title="Voltar"
                    size="sm"
                    variant="dark"
                    class="mt-2 mb-2 ml-2 fa fa-reply"
                    v-if="
                        mode === 'remove' || mode === 'save' || mode === 'read'
                    "
                    @click="reset"
                ></b-button>
            </b-col>
        </b-row>
        <b-row v-show="mode !== 'save' && mode !== 'remove'">
            <div class="col-sm-6">
                <b-form-group>
                    <input
                        size="lg"
                        type="text"
                        icon="search"
                        v-model="search"
                        placeholder="Informe a descrição"
                        class="form-control"
                    />
                </b-form-group>
            </div>
            <transition name="slide" type="animation" appear>
                <b-table
                    :busy="isBusy"
                    class="table-responsive"
                    hover
                    key="slide"
                    head-variant="light"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="filteredList"
                    :fields="fields"
                >
                    <template v-slot:table-busy>
                        <div class="text-center text-info my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Carregando...</strong>
                        </div>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button
                            title="Editar"
                            size="sm"
                            variant="light"
                            @click="loadtype_document(data.item)"
                            class="mr-2 mt-2"
                        >
                            <i class="fa fa-pencil"></i>
                        </b-button>
                    </template>
                </b-table>
            </transition>
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="rows"
            >
            </b-pagination>
        </b-row>
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'TypeDocumentAdmin',

    data: function() {
        return {
            mode: 'insert',
            type_document: {},
            type_documents: [],
            search: '',
            showTable: false,
            showSearch: true,
            isBusy: false,
            currentPage: 1,
            perPage: 5,
            fields: [
                { key: 'id', label: 'Código', sortable: true },
                {
                    key: 'name_type_document',
                    label: 'Descrição',
                    sortable: true
                },
                {
                    key: 'initial_type_document',
                    label: 'Index Base',
                    sortable: false
                },
                {
                    key: 'blocked',
                    label: 'Bloqueado',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                 {
                    key: 'has_date',
                    label: 'Validade?',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                 {
                    key: 'require_employee',
                    label: 'Obrig. Func.',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                 {
                    key: 'require_responsible',
                    label: 'Obrig. Resp.',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                {
                    key: 'url_type_document',
                    label: 'Url',
                    sortable: false
                },
                {
                    key: 'name_file',
                    label: 'Arquivo Modelo',
                    sortable: false
                },
                { key: 'actions', label: 'Ações', variant: 'secondary' }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        filteredList() {
            return this.type_documents.filter(type_document => {
                return type_document.name_type_document
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
            })
        },
        icon() {
            return this.showTable ? 'fa-retweet' : 'fa-search'
        },
        rows() {
            return this.type_documents.length
        }
    },
    methods: {
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        loadTypeDocuments() {
            const url = `${baseApiUrl}/typedocuments`
            axios.get(url).then(res => {
                this.type_documents = res.data
                this.isBusy = false
            })
        },
        alterModo() {
            this.mode = 'save'
            this.type_document = {}
            this.$refs.searchFocus.focus()
        },
        save() {
            const method = this.type_document.id ? 'put' : 'post'
            const id = this.type_document.id ? `/${this.type_document.id}` : ''
            axios[method](
                `${baseApiUrl}/typedocuments${id}`,
                this.type_document
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        reset() {
            this.mode = 'insert'
            this.loadTypeDocuments()
        },
        remove() {
            const id = this.type_document.id
            axios
                .delete(`${baseApiUrl}/typedocuments/${id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadtype_document(type_document, mode = 'save') {
            this.mode = mode
            this.type_document = { ...type_document }
            this.$refs.searchFocus.focus()
        },
        async saveImagem() {
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('type_document_id_storage', this.type_document.id)

            await axios
                .post(`${baseApiUrl}/storagefiles`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.getLastStorageFile()
                })
                .catch(showError)
        },
        async getLastStorageFile() {

             const url = `${baseApiUrl}/storagefilestypedocumentbylast/?name_file=${this.file.name}&type_document_id_storage=${this.type_document.id}`
             await axios(url).then(
                 res => (
                    this.type_document.model_attach_id_type_document = res.data.id
                 )
             )

        }
    },
    mounted() {
        this.loadTypeDocuments()
        this.showTable = true
    }
}
</script>

<style>
.type_document-admin select {
    font-size: 0.75rem;
}
.type_document-admin input {
    font-size: 0.75rem;
}
.type_document-admin textarea {
    font-size: 0.75rem;
}
.type_document-admin button {
    font-size: 0.75rem;
}
.type_document-admin table {
    font-size: 0.75rem;
}

@keyframes slide-in {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(40px);
    }
}

.slide-enter-active {
    animation: slide-in 1s ease;
    transition: opacity 1s;
}

.slide-leave-active {
    animation: slide-out 1s ease;
    transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
</style>
